import axios, { AxiosError } from 'axios';
import { QueryOutput } from '@aws-sdk/client-dynamodb';
import decodeJwt, { JwtPayload } from 'jwt-decode';
import { getStore } from './ApplicationState';
import { LoginState, refreshToken } from './UserManagement';
import { isEntryThroughTryout } from './GlobalFunctions';
import {
  getRefreshedCognitoUser,
  isTokenUnavailable,
  waitUntilTokenIsAvailable,
} from './UserManagementHelpers';
import { getAppRoot } from './identity';
import { isTokenExpired } from './GlobalFunctions';
import { getCookie } from './aaa';
import {
  ClassLinkApiSection,
  ClassLinkApiStudentWithSection,
  ClassroomType,
  CleverApiSectionResult,
  CleverApiStudentResult,
  DistrictNames,
  FetchConfiguration,
  GetCleverTokenResponse,
  MyPeekavilleUnitLocksObjectType,
  SchoolModel,
  SellerNames,
  StudentDBObjectType,
  SyncClassroomRequest,
  UserProfileType,
} from './peekapak-types/DataProtocolTypes';
import {
  Assignment,
  ELAStandardsMap,
  ModuleResult,
  Unit,
} from './peekapak-types/LessonPlanTypes';
import { StruggleDataPoint } from './peekapak-types/PeekavilleGameDataTypes';

let commonServicesRoot: string;
let aaaServicesRoot: string;
let peekavilleBackendRoot: string;
let cleverServicesRoot: string;
let binaryServicesRoot: string;
let stripeServicesRoot: string;
let zohoServicesRoot: string;
let lessonServicesRoot: string;
let mediaServicesRoot: string;
let classLinkServicesRoot: string;

if (import.meta.env.REACT_TEST_PROD_API) {
  commonServicesRoot = 'https://api.peekapak.com/commonservices';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservices';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekaville';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservices';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservices';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservices';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservices';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservices';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservices';
} else if (
  import.meta.env.MODE === 'development' ||
  import.meta.env.REACT_APP_USE_DEV_API
) {
  commonServicesRoot = 'https://api.peekapak.com/commonservicesdev';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservicesdev';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekavilledev';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservicesdev';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservicesdev';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservicesdev';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservicesdev';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservicesdev';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservicesdev';
} else {
  commonServicesRoot = 'https://api.peekapak.com/commonservices';
  aaaServicesRoot = 'https://api.peekapak.com/aaaservices';
  peekavilleBackendRoot = 'https://api.peekapak.com/peekaville';
  binaryServicesRoot = 'https://api.peekapak.com/binaryservices';
  stripeServicesRoot = 'https://api.peekapak.com/stripeservices';
  zohoServicesRoot = 'https://api.peekapak.com/zohoservices';
  cleverServicesRoot = 'https://api.clever.com/v3.0';
  lessonServicesRoot = 'https://api.peekapak.com/lessonservices';
  mediaServicesRoot = 'https://api.peekapak.com/mediaservices';
  classLinkServicesRoot = 'https://api.peekapak.com/classlinkservices';
}

interface CleverError {
  code: number;
  error: string;
  data: Record<string, unknown>;
}

const withBackendInterface =
  (WrappedComponent: ComponentType<{}>) => (props: {}) => (
    <WrappedComponent
      onGetUserProfile={getUserProfile}
      onRetrieveGameState={retrieveGameState}
      onStoreGameState={storeGameState}
      onStoreUserGeneratedImage={storeUserGeneratedImage}
      onCreateJournalEntry={createJournalEntry}
      onRecordInteractionEvent={recordInteractionEvent}
      {...props}
    />
  );

export function convertAxiosError(axiosError: AxiosError) {
  if (axiosError.response) {
    return new Error(axiosError.response.data.message);
  }

  return axiosError;
}

/* eslint-enable no-unused-vars */
function retrievePeekavilleUnitLocks(
  classroomId: string,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/${classroomId}/game-unit-locks`;
  getAuthenticatedFetchConfiguration('GET').then((configuration) => {
    fetchWithCallbacks(
      endpoint,
      configuration,
      successCallback,
      failureCallback,
    );
  });
}

function storePeekavilleUnitLocks(
  classroomId: string,
  peekavilleUnitLocks: MyPeekavilleUnitLocksObjectType,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/${classroomId}/game-unit-locks`;
  peekavilleUnitLocks['Self Regulation'] =
    peekavilleUnitLocks['Self-Regulation'];
  delete peekavilleUnitLocks['Self-Regulation'];
  getAuthenticatedFetchConfiguration('PUT', peekavilleUnitLocks).then(
    (configuration) => {
      fetchWithCallbacks(
        endpoint,
        configuration,
        successCallback,
        failureCallback,
      );
    },
  );
}

async function fetchFromGallery(
  successCallback: (arg0: QueryOutput) => unknown,
  failureCallback: (arg0: { message: string }) => unknown,
  classroomId: string,
  numberOfItems?: number,
  applicationFilter?: string,
  exclusiveStartKey?: Record<string, unknown>,
) {
  const endpoint = `${commonServicesRoot}/classrooms/gallery`;
  const params = {
    classroomId,
    exclusiveStartKey,
    numberOfItems,
    applicationFilter,
  };
  const configuration = await getAuthenticatedFetchConfiguration(
    'POST',
    params,
  );
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

async function getUserProfileForMigration(
  successCallback: (arg0: UserProfileType) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const userId = getUserId();
  const username = getUsername();
  const email = getUsersEmail();
  const idP = getCookie('peekapak.idP');
  const endpoint = `${aaaServicesRoot}/users/${userId}`;
  const params = {
    userId,
    email,
    username,
    idP,
  };
  const configuration = await getAuthenticatedFetchConfiguration(
    'POST',
    params,
  );
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

function resendNewPasswordConfirmation(
  username: string,
  successCallback: (arg0: Record<string, unknown>) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/admin/resend-new-password-confirmation`;
  const configuration = getFetchConfiguration('POST', {
    username,
  });
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

async function syncClassroom(
  syncClassroomRequest: SyncClassroomRequest,
  successCallback: (arg0: { jobId: string }) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/classrooms/import-async`;
  const params = { ...syncClassroomRequest };
  const configuration = await getAuthenticatedFetchConfiguration(
    'POST',
    params,
  );
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

function createTeacherAccount(
  createTeacherRequest: {
    createOrigin: string;
  },
  successCallback: (value: StudentDBObjectType) => unknown,
  failureCallback: (error: Error) => unknown,
) {
  createTeacherRequest.createOrigin = document.location.href;
  const endpoint = `${aaaServicesRoot}/users/create-teacher`;
  const configuration = getFetchConfiguration('POST', createTeacherRequest);
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

async function createStudentAccount(studentObj: StudentDBObjectType) {
  const endpoint = `${aaaServicesRoot}/users/create-student`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, studentObj, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function removeStudentAccount(
  userId: string,
  teachersUserId: string,
  classroomId: string,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const endpoint = `${commonServicesRoot}/users/remove-student`;
  const configuration = getFetchConfiguration('PUT', {
    userId,
    teachersUserId,
    classroomId,
  });
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      } else {
        failureCallback(response);
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

function optinUserEmail() {
  const endpoint = `${commonServicesRoot}/subscribe/all/subscribed`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'put',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getCleverToken(
  authorizationCode: string,
  redirectUri: string,
  successCallback: (arg0: GetCleverTokenResponse) => unknown,
  failureCallback: (arg0: Record<string, unknown>) => unknown,
) {
  const endpoint = `${aaaServicesRoot}/users/get-clever-token`;
  const configuration = getFetchConfiguration('POST', {
    authorizationCode,
    redirectUri,
  });
  fetchWithCallbacks(endpoint, configuration, successCallback, failureCallback);
}

type CleverSection = {
  data: {
    grade: string;
    id: string;
    name: string;
    students: Array<Record<string, unknown>>;
  };
};

function getCleverOwnProfile(authorizationCode: string) {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/me`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function getClassLinkSections(
  teacherSourcedId: string,
): Promise<ClassLinkApiSection[]> {
  const endpoint = `${classLinkServicesRoot}/users/${teacherSourcedId}/sections`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

async function getClassLinkStudents(
  sectionSourcedId: string,
): Promise<ClassLinkApiStudentWithSection[]> {
  const endpoint = `${classLinkServicesRoot}/sections/${sectionSourcedId}/students`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios.get(endpoint, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    return result.data;
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    throw e;
  }
}

function getCleverSections(
  authorizationCode: string,
  teacherCleverId: string,
): Promise<CleverApiSectionResult> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${teacherCleverId}/sections`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

function getCleverSection(
  authorizationCode: string,
  sectionId: string,
): Promise<CleverSection> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/sections/${sectionId}`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

type StudentsInSection = {
  data: Array<{
    data: {
      id: string;
      name: {
        first: string;
        last?: string;
      };
      grade: string;
    };
  }>;
};

function getAllMyCleverStudents(
  authorizationCode: string,
  teacherUserId: string,
): Promise<CleverApiStudentResult> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${teacherUserId}/mystudents`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

function getCleverStudent(authorizationCode: string, studentUserId: string) {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/users/${studentUserId}`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

function getCleverStudentsInSection(
  authorizationCode: string,
  sectionId: string,
): Promise<StudentsInSection> {
  return new Promise((resolve, reject) => {
    const endpoint = `${cleverServicesRoot}/sections/${sectionId}/students`;
    const authorizationString = `Bearer ${authorizationCode}`;
    axios
      .get(endpoint, {
        headers: {
          Authorization: authorizationString,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => {
        const e = error as AxiosError<CleverError>;
        if (e.response?.data.code === 91) {
          return resolve({ data: [] });
        }
        const errorText = `Error code ${e.response?.data.code}: ${e.response?.data.error}`;
        console.error(`Error communicating with server: ${errorText}`);
        reject(errorText);
      });
  });
}

function updateStudent(
  userId: string,
  newData: unknown,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const dataObj = {
    userId,
    newData,
  };
  const endpoint = `${commonServicesRoot}/users/update-student`;
  const configuration = getFetchConfiguration('PUT', dataObj);
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      } else {
        failureCallback(response);
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

function updateDefaultStudentPassword(
  userId: string,
  defaultStudentPassword: string,
  successCallback: (arg0: Response) => unknown,
  failureCallback: (arg0: Response) => unknown,
) {
  const dataObj = {
    userId,
    defaultStudentPassword,
  };
  const endpoint = `${commonServicesRoot}/users/update-default-student-password`;
  const configuration = getFetchConfiguration('PUT', dataObj);
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        successCallback(response);
      } else {
        failureCallback(response);
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

//
//
// private functions
//
//
function getUser() {
  return getStore()?.getState()?.user?.cognitoProfile;
}

function fetchWithCallbacks(
  endpoint: string,
  configuration: FetchConfiguration,
  successCallback: (arg0: any) => any,
  failureCallback: (arg0: any) => any,
) {
  fetch(endpoint, configuration)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          successCallback(data);
        });
      } else {
        response.json().then((data) => {
          failureCallback(data);
        });
      }
    })
    .catch((error) => {
      console.error(`Error communicating with server: ${error}`);
      failureCallback(error);
    });
}

function getFetchConfiguration(
  method: string,
  content?: Record<string, unknown>,
): FetchConfiguration {
  if (content) {
    const contentString: string = JSON.stringify(content);
    return {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': contentString.length.toString(),
      },
      body: contentString,
    };
  }

  return {
    method,
    headers: {},
  };
}

async function getAuthenticatedFetchConfiguration(
  method: string,
  content?: Record<string, unknown>,
) {
  const configuration = getFetchConfiguration(method, content);
  const authorizationToken = await getAuthorizationToken();

  if (configuration.headers) {
    configuration.headers['Authorization'] = authorizationToken;
  }

  return configuration;
}

function getUserId() {
  const idToken = getUser()
    ?.getSignInUserSession()
    ?.getIdToken()
    ?.getJwtToken();
  if (!idToken) throw new Error(`User is not logged in`);
  const decodedToken = decodeJwt<JwtPayload>(idToken);
  const userId =
    getStore()?.getState()?.user?.userProfile?.userId || decodedToken.sub;
  return userId;
}

function getUsername() {
  const username = getUser()?.getSignInUserSession()?.getIdToken()?.payload[
    'cognito:username'
  ];

  if (!username) throw new Error(`User is not logged in`);

  return username;
}

function getUsersEmail() {
  return getUser()?.getSignInUserSession()?.getIdToken()?.payload.email;
}

export function getExistingAuthorizationToken(
  raw: boolean = true,
): string | undefined {
  const store = getStore();
  const currentState = store.getState().user.loginState;

  if (
    currentState !== LoginState.loggedIn &&
    currentState !== LoginState.authenticated
  ) {
    return '';
  }

  const expiry = getUser()?.getSignInUserSession()?.getIdToken()?.payload?.exp;

  if (expiry === undefined) return '';

  if (!isTokenExpired(expiry)) {
    return raw
      ? `${getUser()?.getSignInUserSession()?.getIdToken()?.getJwtToken()}`
      : `Bearer ${getUser()
        ?.getSignInUserSession()
        ?.getIdToken()
        ?.getJwtToken()}`;
  }

  return '';
}

/* eslint-disable complexity */
async function getAuthorizationToken(): Promise<string> {
  const store = getStore();

  if (isTokenUnavailable()) {
    // console.debug(
    //   `%cRoot login state in transition, waiting...`,
    //   'background: #222; color: #bada55'
    // );
    try {
      await waitUntilTokenIsAvailable();
    } catch (e) {
      console.info(
        `Caught exception attempting to get Authorization token = `,
        e,
      );
    }
  }

  const currentState = store.getState().user.loginState;

  if (
    currentState !== LoginState.loggedIn &&
    currentState !== LoginState.authenticated
  ) {
    throw new Error(
      `User is not logged in or not authenticated: ${currentState}`,
    );
  }

  const expiry = getUser()?.getSignInUserSession()?.getIdToken()?.payload?.exp;

  if (expiry === undefined)
    throw new Error(`Expiry is undefined or user is not logged in`);

  if (!isTokenExpired(expiry)) {
    return `Bearer ${getUser()
      ?.getSignInUserSession()
      ?.getIdToken()
      ?.getJwtToken()}`;
  }

  try {
    const cognitoUser = await getRefreshedCognitoUser();

    store.dispatch(refreshToken(cognitoUser));
    return `Bearer ${getUser()
      ?.getSignInUserSession()
      ?.getIdToken()
      ?.getJwtToken()}`;
  } catch (error) {
    console.error(`Error communicating with server: ${error}`);
    throw error;
  }
}

function getSchools(
  district?: string,
  tableOverride?: string,
): Promise<Array<Record<string, unknown>>> {
  const endpoint = (() => {
    let ep = district
      ? `${commonServicesRoot}/admin/schools/${district}`
      : `${commonServicesRoot}/admin/schools`;
    if (tableOverride) ep += `/${tableOverride}`;
    return ep;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

interface CompletedLessonsResult {
  // unit name: number of completed lessons
  [key: string]: number;
}

function getCompletedLessonsCounts(
  teachersEmail: string,
  tableOverride: string,
): Promise<CompletedLessonsResult[]> {
  return new Promise((resolve, reject) => {
    const endpoint = (() => {
      let ep = `${commonServicesRoot}/admin/count-completed-lessons/${encodeURIComponent(
        teachersEmail,
      )}/2021`;
      if (tableOverride) ep += `/${tableOverride}`;
      return ep;
    })();
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getMiddleSchoolUnit(
  unitId: string,
  _language: string,
  grade: string,
): Promise<Unit> {
  const endpoint = `${lessonServicesRoot}/unit/${unitId}/en/${grade}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getELAStandardsMap(grade: string): Promise<ELAStandardsMap> {
  const endpoint = `${lessonServicesRoot}/lessons/standards/grade/${grade}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: { Authorization: auth },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getMiddleSchoolModule(
  moduleId: string,
  _language: string,
  grade: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/module/${moduleId}/en/${grade}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getMiddleSchoolModules(
  moduleIDs: string[],
  _language: string,
  grade: string,
): Promise<ModuleResult[]> {
  const endpoint = `${lessonServicesRoot}/module`;
  const postBody = moduleIDs.map((x: string) => {
    return { id: x, language: 'en', grade: grade };
  });

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function createGroupAssignments(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/create`;
  const postBody = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function createIndividualAssignment(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
  userId: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/create`;
  const postBody = {
    type: 'individual',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
    userId: userId,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function deleteGroupAssignments(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/delete`;
  const postBody = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .delete(endpoint, { data: postBody, headers: { Authorization: auth } })
        .then((response) => {
          resolve(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function deleteIndividualAssignments(
  userId: string,
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/delete`;
  const postBody = {
    type: 'individual',
    userId: userId,
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .delete(endpoint, { data: postBody, headers: { Authorization: auth } })
        .then((response) => {
          resolve(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function listGroupAssignments(
  classroomId: string,
  unit: string,
  lesson: string,
  thisModule: string,
  language: string,
  grade: number,
): Promise<Assignment[]> {
  const endpoint = `${lessonServicesRoot}/assignments/list`;
  const postBody = {
    type: 'group',
    classroomId: classroomId,
    unit: unit,
    lesson: lesson,
    module: thisModule,
    language,
    grade: grade,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function listStudentAssignments(
  userId: string,
  language: string,
  grade: number,
): Promise<Assignment[]> {
  const endpoint = `${lessonServicesRoot}/assignments/list`;
  const postBody = {
    type: 'individual',
    userId,
    language,
    grade: grade,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function retrieveAssignment(
  userId: string,
  createdAt: number,
): Promise<Assignment[]> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/retrieve`;
    const postBody = {
      userId,
      createdAt: createdAt,
    };
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function retractAssignment(
  userId: string,
  createdAt: number,
): Promise<Record<string, unknown>> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/retract`;
    const postBody = {
      userId,
      createdAt: createdAt,
    };
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function saveAssignment(
  userId: string,
  createdAt: number,
  content: Record<string, unknown>[] | Record<string, unknown>,
  metawork?: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  return new Promise((resolve, reject) => {
    const endpoint = `${lessonServicesRoot}/assignments/save`;
    const postBody = {
      userId: userId,
      createdAt: createdAt,
      work: { content: content },
      metawork: metawork || '',
    };
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function submitAssignment(
  userId: string,
  createdAt: number,
  content: Record<string, unknown>[] | Record<string, unknown>,
  metawork?: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  const endpoint = `${lessonServicesRoot}/assignments/submit`;
  const postBody = {
    userId: userId,
    createdAt: createdAt,
    work: { content: content },
    metawork: metawork || '',
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .post(endpoint, postBody, {
          headers: { Authorization: auth },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function deemLessonComplete(
  classroomId: string,
  unitId: string,
  lessonId: string,
): Promise<void> {
  const endpoint = `${commonServicesRoot}/classroom/${classroomId}/deem-complete/unit/${unitId}/lesson/${lessonId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, null, { headers: { Authorization: auth } })
        .then(() =>
          resolve(console.log('Lesson marked as complete in database')),
        )
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function undoDeemComplete(
  classroomId: string,
  unitId: string,
  lessonId: string,
): Promise<void> {
  const endpoint = `${commonServicesRoot}/classroom/${classroomId}/undo-deem-complete/unit/${unitId}/lesson/${lessonId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .patch(endpoint, null, { headers: { Authorization: auth } })
        .then(() =>
          resolve(console.log('Lesson no longer deeemd complete in database')),
        )
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getVideoURL(assetId: string): Promise<string> {
  return new Promise((resolve, reject) => {
    getAuthorizationToken()
      .then((auth) => {
        const endpoint = `${mediaServicesRoot}/video-url/service/mux/asset/${assetId}`;
        axios
          .get(endpoint, { headers: { Authorization: auth } })
          .then((response) => resolve(response.data.url))
          .catch((error) => {
            console.error(
              `Error communicating with server: ${convertAxiosError(error)}`,
            );
            reject(convertAxiosError(error));
          });
      })
      .catch((error) => {
        if ((error as Error)?.message.includes('User is not logged in')) {
          const endpoint = `${mediaServicesRoot}/video-url/public/service/mux/asset/${assetId}`;
          axios
            .get(endpoint)
            .then((response) => resolve(response.data.url))
            .catch((error) => {
              console.error(
                `Error communicating with server: ${convertAxiosError(error)}`,
              );
              reject(convertAxiosError(error));
            });

          return;
        }

        reject(error);
      });
  });
}

function getUsers(
  schoolId: string,
  tableOverride: string,
): Promise<Array<Record<string, unknown>>> {
  const endpoint = (() => {
    let ep = `${commonServicesRoot}/admin/users-async/by-school/${schoolId}`;
    if (tableOverride) ep += `/${tableOverride}`;
    return ep;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios
        .get(endpoint, {
          headers: {
            Authorization: auth,
          },
        })
        .then((response) => resolve(response.data.jobId))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function storeNewSchool(schoolModel: SchoolModel): Promise<void> {
  const endpoint = `${commonServicesRoot}/admin/schools/new`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        data: schoolModel,
        headers: {
          Authorization: auth,
        },
      })
        .then(() => resolve())
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

interface QueryMixPanelParams {
  jql: string;
  database: string;
  tableOverride?: string;
}

function queryMixpanelByGroup(
  script: string,
  database: string,
  tableOverride?: string,
): Promise<any[]> {
  const endpoint = `${binaryServicesRoot}/admin/query-mixpanel-by-group`;
  const payload: QueryMixPanelParams = (() => {
    const p: QueryMixPanelParams = {
      jql: script,
      database,
    };
    if (tableOverride) {
      p.tableOverride = tableOverride;
    }
    return p;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        data: JSON.stringify(payload),
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
            return reject(
              new Error(
                `Server experienced error trying to retrieve analytics: ${error.response.status}. Please try again or contact Peekapak Support`,
              ),
            );
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error(error.request);
            return reject(
              new Error(
                `Did not receive response from server. Please try again or contact Peekapak Support`,
              ),
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            return reject(
              new Error(
                `A problem occurred while retrieving analytics data. Please try again or contact Peekapak Support`,
              ),
            );
          }
        });
    });
  });
}

function flushLessonCache(): Promise<void> {
  const endpoint = `${getAppRoot()}api/flushLessonCache`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'post',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((_response) => resolve())
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getCountryOriginBasedOnIp(): Promise<{
  country: string;
  country_code: string;
  sourceIp: string;
}> {
  return new Promise((resolve, reject) => {
    const endpoint = `${commonServicesRoot}/users/get-country-origin`;
    axios({
      method: 'get',
      url: endpoint,
    })
      .then((response) => resolve(response.data))
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

function validateLicenseCode(code: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (code.length < 12) {
      resolve(false);
    }

    const endpoint = `${aaaServicesRoot}/license-code/${code}/validate`;
    axios({
      method: 'get',
      url: endpoint,
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

function redeemLicenseCode(code: string): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const endpoint = `${aaaServicesRoot}/license-code/${code}/redeem/${userId}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'put',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getJob(id: string): Promise<{
  status: string;
  data: string;
}> {
  const endpoint = `${commonServicesRoot}/jobs/${id}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getSlideShowPointers(): Promise<{
  url: string;
  thumbnailUrl: string;
}> {
  const endpoint = `${commonServicesRoot}/configuration/SlideShowPointers`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function updateClassroom(
  teachersEmail: string,
  className: string,
  newData: unknown,
): Promise<{
  status: string;
}> {
  const endpoint = `${commonServicesRoot}/classroom`;
  const payload = {
    teachersEmail,
    className,
    newData,
  };
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'put',
        url: endpoint,
        data: payload,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => resolve(response.data))
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getFromApplicationConfiguration(
  configurationName: string,
): Promise<Record<string, unknown>[]> {
  const endpoint = `${commonServicesRoot}/configuration/${configurationName}`;
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

async function getResellers(): Promise<Record<string, string>[]> {
  const data = (await getFromApplicationConfiguration(
    'SellerNames',
  )) as unknown as SellerNames;
  const asList: { id: string; name: string }[] = Object.keys(data).map((e) => ({
    id: e,
    name: data[e].name,
  }));
  return asList;
}

async function getDistricts(): Promise<Record<string, unknown>[]> {
  const data = (await getFromApplicationConfiguration(
    'DistrictNames',
  )) as unknown as DistrictNames;
  const asList = Object.keys(data).map((e) => ({
    id: e,
    name: data[e].name,
  }));
  return asList;
}

async function getStudentNumberLimits(): Promise<Record<string, unknown>[]> {
  return await getFromApplicationConfiguration('StudentNumberLimits');
}

function getClassrooms(
  email: string,
  tableOverride: string,
): Promise<Array<ClassroomType>> {
  const endpoint = (() => {
    const e = `${commonServicesRoot}/classroom/${encodeURIComponent(
      email,
    )}/by-email/2021`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getGameProgress(
  userId: string,
  tableOverride: string,
): Promise<Array<ClassroomType>> {
  const endpoint = (() => {
    const e = `${peekavilleBackendRoot}/users/${userId}/game-progress`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();
  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getJournals(
  userId: string,
  tableOverride: string,
): Promise<Array<ClassroomType>> {
  const endpoint = (() => {
    const e = `${peekavilleBackendRoot}/users/${userId}/journals`;
    if (!tableOverride) return e;
    return `${e}/${tableOverride}`;
  })();

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

function getStruggleData(userId: string): Promise<Array<StruggleDataPoint>> {
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journals/struggle`;

  return new Promise((resolve, reject) => {
    getAuthorizationToken().then((auth) => {
      axios({
        method: 'get',
        url: endpoint,
        headers: {
          Authorization: auth,
        },
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(
            `Error communicating with server: ${convertAxiosError(error)}`,
          );
          reject(convertAxiosError(error));
        });
    });
  });
}

interface LicenseAndKeyRing {
  licenseLevel: string;
  licenseExpires: number;
  flags: Record<string, boolean>;
  keyRing: string[];
}

async function activateTrial(): Promise<LicenseAndKeyRing> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${aaaServicesRoot}/users/${userId}/activate-trial`;

  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function updateUserProfile(
  newUserData: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${aaaServicesRoot}/users/${userId}/update`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: newUserData,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function getCustomer(): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/get-customer`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        userId,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function createSubscription(
  customerId: string,
  paymentMethodId: string,
  tier: string,
  region: string,
  promoCode?: string,
): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/create-subscription`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        userId,
        customerId,
        paymentMethodId,
        tier,
        region,
        promoCode,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function previewInvoice(
  tier: string,
  region: string,
  promoCode: string,
): Promise<Record<string, unknown>> {
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/preview-invoice`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data: {
        tier,
        region,
        promoCode,
      },
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function getPaymentStatus(): Promise<Record<string, unknown>> {
  const userId = getUserId();
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/get-payment-status/${userId}`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function validatePromoCode(
  code: string,
): Promise<Record<string, unknown>> {
  const auth = await getAuthorizationToken();
  const endpoint = `${stripeServicesRoot}/promo-code/${code}/validate`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: auth,
      },
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

async function createLead(
  data: Record<string, unknown>,
): Promise<Record<string, unknown>> {
  const endpoint = `${zohoServicesRoot}/create-lead`;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: endpoint,
      data,
    })
      .then((response) => resolve(response.data.result))
      .catch((error) => {
        console.error(
          `Error communicating with server: ${convertAxiosError(error)}`,
        );
        reject(convertAxiosError(error));
      });
  });
}

function getSignUpInformation(
  classroomId: string,
): Promise<SignUpInformation | null> {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${commonServicesRoot}/signup-information/${classroomId}`;
    axios
      .get(endpoint)
      .then((response) => {
        if (response.data.status) {
          return resolve(null);
        }

        return resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

async function createJournalEntry(
  journalEntry: {},
  successCallback: () => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    return successCallback();
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/journal`;
  const authorizationToken = await getAuthorizationToken();
  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: journalEntry,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

function recordConsentAndCreateAccount(
  record: {},
  successCallback: () => any,
  failureCallback: () => any,
) {
  return new Promise((resolve, reject) => {
    const endpoint = `${aaaServicesRoot}/users/create-on-consent`;
    const configuration = getFetchConfiguration('POST', record);
    fetchWithCallbacks(
      endpoint,
      configuration,
      (data) => {
        return resolve(data);
      },
      (error) => {
        return reject(error);
      },
    );
  });
}

function recordInteractionEvent(eventType: string, eventName: string) {
  if (isEntryThroughTryout()) {
    return;
  }

  // tracker.sendInteractionEvent(eventType, eventName);
}

function getTryOutGameState() {
  return new Promise((resolve, reject) => {
    const endpoint = `${peekavilleBackendRoot}/users/get-try-out-game-state`;
    const configuration = getFetchConfiguration('GET');
    fetchWithCallbacks(
      endpoint,
      configuration,
      (data) => {
        return resolve(data);
      },
      (error) => {
        return reject(error);
      },
    );
  });
}

async function retrieveGameState(
  successCallback: (result) => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    try {
      const augmentedGameState = await getTryOutGameState();
      const gameStateCookie = sessionStorage.getItem(
        'peekapak.peekavilleGameState',
      );

      if (gameStateCookie) {
        const saveFile = JSON.parse(gameStateCookie);
        augmentedGameState.gameState = {
          ...augmentedGameState.gameState,
          ...saveFile,
        };
      }

      successCallback(augmentedGameState);
    } catch (error) {
      failureCallback();
    }
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/game-state`;
  const authorizationToken = await getAuthorizationToken();

  try {
    const result = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback(result.data);
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

async function sendComprehensionData(
  comprehensionData: {},
  successCallback: () => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    return successCallback();
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/comprehension-data`;
  const authorizationToken = await getAuthorizationToken();
  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: comprehensionData,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

async function sendConsoleCommand(
  command: string,
  successCallback: () => any,
  failureCallback: () => any,
) {
  const endpoint = `${peekavilleBackendRoot}/admin/api-console`;
  const authorizationToken = await getAuthorizationToken();
  const commandRequest = {
    command,
    userId: getUserId(),
  };
  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: commandRequest,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

async function sendStruggleData(
  struggleData: {},
  successCallback: () => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    return successCallback();
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/struggle-data`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios({
      method: 'post',
      url: endpoint,
      data: struggleData,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

function signUpStudentAccount(
  payload: SignUpPayload,
): Promise<StudentAccountInformation | null> {
  return new Promise(async (resolve, reject) => {
    const endpoint = `${aaaServicesRoot}/users/signup-student`;
    axios({
      method: 'post',
      url: endpoint,
      data: payload,
    })
      .then((response) => {
        if (response.data.status) {
          return resolve(null);
        }

        return resolve(response.data);
      })
      .catch((error) => reject(error));
  });
}

async function storeGameState(
  gameState: {},
  successCallback: () => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    sessionStorage.setItem(
      'peekapak.peekavilleGameState',
      JSON.stringify(gameState),
    );
    const { tryoutProfile } = getStore().getState().entry;
    const { requestOid } = tryoutProfile;

    if (requestOid) {
      const endpoint = `${peekavilleBackendRoot}/users/tryout/${requestOid}/game-state`;
      const configuration = getFetchConfiguration('PUT', gameState);
      return fetchWithCallbacks(
        endpoint,
        configuration,
        successCallback,
        failureCallback,
      );
    }

    return successCallback();
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/game-state`;
  const authorizationToken = await getAuthorizationToken();
  try {
    await axios({
      method: 'put',
      url: endpoint,
      data: gameState,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback();
  }
}

async function storeUserGeneratedImage(
  applicationName: string,
  displayTitle: string,
  imageAsDataUri: string,
  successCallback: () => any,
  failureCallback: () => any,
) {
  if (isEntryThroughTryout()) {
    return successCallback();
  }

  const userId = getUserId();
  const endpoint = `${peekavilleBackendRoot}/users/${userId}/user-generated-content`;
  const authorizationToken = await getAuthorizationToken();
  const splitUpDataUri = imageAsDataUri.split(',');
  const mimeTypeSplit = splitUpDataUri[0].split('data:')[1].split(';');
  const payload = {
    application: applicationName,
    displayTitle,
    mediaType: mimeTypeSplit[0],
    charsetOrEncoding: mimeTypeSplit[1],
    content: splitUpDataUri[1],
  };

  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: payload,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback();
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback(error);
  }
}

async function getUserProfile(
  successCallback: () => any,
  failureCallback: () => any,
) {
  const userId = getUserId();
  const endpoint = `${aaaServicesRoot}/users/${userId}`;
  const authorizationToken = await getAuthorizationToken();
  try {
    const result = await axios({
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: authorizationToken,
      },
    });
    successCallback(result.data);
  } catch (error) {
    const e = convertAxiosError(error as AxiosError);
    console.error(`Error communicating with server: ${e}`);
    failureCallback(error);
  }
}

export {
  activateTrial,
  createLead,
  createStudentAccount,
  createSubscription,
  createTeacherAccount,
  createJournalEntry,
  fetchFromGallery,
  flushLessonCache,
  getAuthorizationToken,
  getClassrooms,
  getClassLinkSections,
  getClassLinkStudents,
  getCleverOwnProfile,
  getCleverSection,
  getCleverSections,
  getCleverStudentsInSection,
  getCleverStudent,
  getAllMyCleverStudents,
  getCleverToken,
  getCompletedLessonsCounts,
  getCountryOriginBasedOnIp,
  getCustomer,
  getDistricts,
  getGameProgress,
  getJob,
  getJournals,
  getMiddleSchoolUnit,
  getMiddleSchoolModules,
  getMiddleSchoolModule,
  getSignUpInformation,
  createIndividualAssignment,
  createGroupAssignments,
  deleteGroupAssignments,
  deleteIndividualAssignments,
  listStudentAssignments,
  listGroupAssignments,
  retrieveAssignment,
  retractAssignment,
  saveAssignment,
  submitAssignment,
  deemLessonComplete,
  undoDeemComplete,
  getELAStandardsMap,
  getVideoURL,
  getPaymentStatus,
  getSchools,
  getResellers,
  getSlideShowPointers,
  getStudentNumberLimits,
  getStruggleData,
  getUserProfileForMigration,
  getUsers,
  syncClassroom as importClassroom,
  previewInvoice,
  queryMixpanelByGroup,
  redeemLicenseCode,
  removeStudentAccount,
  optinUserEmail,
  recordConsentAndCreateAccount,
  recordInteractionEvent,
  resendNewPasswordConfirmation,
  retrievePeekavilleUnitLocks,
  retrieveGameState,
  sendComprehensionData,
  sendConsoleCommand,
  sendStruggleData,
  signUpStudentAccount,
  storeGameState,
  storeNewSchool,
  storePeekavilleUnitLocks,
  storeUserGeneratedImage,
  updateClassroom,
  updateDefaultStudentPassword,
  updateStudent,
  updateUserProfile,
  validateLicenseCode,
  validatePromoCode,
};

export default withBackendInterface;
