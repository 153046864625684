import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import loadScript from 'load-script';
import App from './App';
import { unregister } from './registerServiceWorker';
import { getStore, initializeApplicationState } from './ApplicationState';
import setupUnityWebGLInterface from './UnityWebGLInterface';
import tracker from './tracker-interface';
import './index.css';

// manage polyfills
const missingFeatures = getMissingFeatures().join(',');

if (missingFeatures) {
  loadScript(
    `https://cdn.polyfill.io/v2/polyfill.min.js?features=${missingFeatures}`,
    main,
  );
} else {
  main();
}

async function main() {
  initializeApplicationState();
  setupUnityWebGLInterface();
  // tracker.startInteraction();
  // endInteractionOnIdle();
  const rootNode = document.getElementById('root');
  const store = getStore();
  renderRouterConfiguration(App);

  function renderRouterConfiguration(Component) {
    if (rootNode) {
      ReactDOM.render(
        <Provider store={store}>
          <BrowserRouterWithBasename>
            <Component />
          </BrowserRouterWithBasename>
        </Provider>,
        rootNode,
      );
      unregister();
    }
  }
}

function BrowserRouterWithBasename(props: { children: JSX.Element }) {
  const basePath = window.location.pathname.includes('/play') ? '/play/' : '/';
  return <BrowserRouter basename={basePath}>{props.children}</BrowserRouter>;
}

function getMissingFeatures() {
  const listOfMissingFeatures = [];

  if (!window.Promise) {
    listOfMissingFeatures.push('Promise');
  } else if (!window.fetch) {
    listOfMissingFeatures.push('fetch');
  }

  return listOfMissingFeatures;
}

function endInteractionOnIdle() {
  // requestIdleCallback shim
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function ricShim(cb: (arg0: {}) => void) {
      const start = Date.now();
      return setTimeout(() => {
        cb({
          didTimeout: false,
          timeRemaining: function timeRemaining() {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1);
    };

  window.requestIdleCallback(onIdleHandler);
}

function onIdleHandler() {
  installIdleTimer();
}

function installIdleTimer() {
  let t: NodeJS.Timer; // set timer to track interaction end event when timeout occurs

  startIdleTimer();
  const interactionEvents = [
    'load',
    'mousemove',
    'mousedown',
    'touchstart',
    'click',
    'keypress',
    'scroll',
  ]; // add listeners to uninstall the idle timer upon any interaction events
  // i'm currently idle so i will now listen for an interaction, because
  // an interaction should cause me to stop listening for interactions
  // until the next time i'm idle

  addListeners(interactionEvents, onInteractionDetected);

  function startIdleTimer() {
    clearTimeout(t);
    t = setTimeout(endInteraction, 60000); // time is in milliseconds
  }

  function clearIdleTimer() {
    clearTimeout(t);
  }

  function endInteraction() {
    // tracker.endInteraction('idle');
    // clearIdleTimer();
  }

  function onInteractionDetected() {
    // removeListeners(interactionEvents, onInteractionDetected);
    // clearIdleTimer();
    // tracker.startInteraction();
    // endInteractionOnIdle();
  }

  function addListeners(eventList, callbackName) {
    eventList.forEach((event) =>
      window.addEventListener(event, callbackName, true),
    );
  }

  function removeListeners(eventList, callbackName) {
    eventList.forEach((event) =>
      window.removeEventListener(event, callbackName, true),
    );
  }
}
