import { Component } from 'react';
import { connect } from 'react-redux';
import { signInUser, signOutUser } from './UserManagementHelpers';
import AppDownloadRedirect from './AppDownloadRedirect';
import LoginBoxV2 from './LoginBoxV2';
import { LoginState, setUserProfile } from './UserManagement';
import { SpinnerOverlay } from './SpinnerOverlay';
import LicenseProblems from './LicenseProblems';
import NoParentalConsent from './NoParentalConsent';
import tracker from './tracker-interface';
import Analytics from './Analytics';
import { getCookie } from './GlobalFunctions';
const myPeekavilleLicenseSet = [
  'CLASSROOM',
  'RENEWED_CLASSROOM',
  'DEVELOPER',
  'PEEKAPAK_SALES',
  'AUTHOR',
  'PUBLISHER',
  'SCHOOL_ADMINISTRATOR',
  'DISTRICT_ADMINISTRATOR',
  'DEMO',
  'TRIAL_CLASSROOM',
  'HOMESCHOOL',
  'LIBRARY',
];
const originReferrerMap = {
  tularecounty: 'ersportal.tcoe.org',
};
type Props = {
  loginState: string;
  userProfile: {
    licenseLevel: string;
    licenseExpires: number;
  };
  location: {
    search: string;
  };
};
type State = {
  loginError: string;
  loginStarted: boolean;
};
declare var peekavilleWebContainer: {
  signalPlayerSignout: () => void;
};

class BaseDashboard extends Component<Props, State> {
  isLicenseExpired = false;
  isLicenseValid = true;
  isParentalConsentRequired = false;
  isParentalConsentReceived = false;

  constructor(props) {
    super(props);
    this.state = {
      loginError: '',
      loginStarted: false,
    };
  }

  hasLicenseProblems = () => !this.isLicenseValid || this.isLicenseExpired;
  hasConsentProblems = () =>
    this.isParentalConsentRequired && !this.isParentalConsentReceived;
  shouldntAllowLogin = () =>
    this.hasConsentProblems() || this.hasLicenseProblems();
  signOutUser = () => {
    if (this.props.loginState === LoginState.loggedIn) {
      signOutUser().catch((error) => {
        console.error(error);
      });
    }
  };
  checkLicense = () => {
    if (!this.props.userProfile) {
      return;
    }

    const { licenseLevel, licenseExpires } = this.props.userProfile;

    if (licenseLevel && licenseExpires) {
      this.isLicenseExpired = !(Date.now() < licenseExpires);
      this.isLicenseValid = myPeekavilleLicenseSet.includes(licenseLevel);
    } else {
      this.isLicenseValid = false;
      this.isLicenseExpired = false;
    }
  };
  checkConsent = () => {
    if (!this.props.userProfile) {
      return;
    }

    const { type, consentStatus, createMethod } = this.props.userProfile;

    if (type === 'Student' && createMethod === 'parent') {
      this.isParentalConsentRequired = true;
      this.isParentalConsentReceived =
        consentStatus && consentStatus.status === 'Received';
    } else {
      this.isParentalConsentRequired = false;
      this.isParentalConsentReceived = false;
    }
  };
  checkLicenseAndConsent = () => {
    this.checkLicense();
    this.checkConsent();
  };
  handleSubmit = (username: string, password: string) => {
    this.setState(
      {
        loginError: '',
        loginStarted: true,
      },
      async () => {
        try {
          const userProfile = await signInUser(username, password);
          this.setState({
            loginStarted: false,
          });
          this.checkLicenseAndConsent();

          if (this.shouldntAllowLogin()) {
            return this.signOutUser();
          }

          const { userId, type, classroomId, district, schoolId } = userProfile;
          const user = {
            id: userId,
            type,
            classroomId,
            district,
            schoolId,
          };
          await Analytics.login(userProfile, 'native');
          this.props.history.push('/game');
        } catch (err) {
          this.signOutUser();
          let message;

          if (
            err.message.includes('UserMigration failed') ||
            err.message.includes(ErrorMessage.NO_ACCOUNT_FOUND)
          ) {
            message =
              'Incorrect username or password. Please check your login info and try again';
          } else if (err.message.indexOf('Failed to fetch') > -1) {
            message =
              'There was a problem that occurred on the server preventing login';
          } else {
            message = err.message;
          }

          this.setState(
            {
              loginError: message,
              loginStarted: false,
            },
            () => console.log(err),
          );
        }
      },
    );
  };

  isIOS = () => {
    var iosQuirkPresent = function() {
      var audio = new Audio();

      audio.volume = 0.5;
      return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
    };

    var isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    var isAppleDevice = navigator.userAgent.includes('Macintosh');
    var isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

    return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
  };

  getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (this.isIOS()) {
      return 'iOS';
    }

    return 'unknown';
  };

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const username = query.get('username');
    const password = query.get('password');
    const origin = query.get('origin');

    if (username && password && origin) {
      // console.debug( `origin = ${ origin.toLowerCase() } referrer = ${ document.referrer }` );
      if (isOriginAuthorized()) {
        this.setState(
          {
            loginStarted: true,
          },
          () => this.handleSubmit(username, password),
        );
      } else {
        this.setState({
          loginError:
            'There is a problem and the attempt to automatically login has failed',
          loginStarted: false,
        });
      }
    }

    function isOriginAuthorized() {
      return (
        origin.toLowerCase() in originReferrerMap &&
        document.referrer.includes(originReferrerMap[origin.toLowerCase()])
      );
    }
  }

  render() {
    this.checkLicenseAndConsent();

    if (this.hasLicenseProblems()) {
      return (
        <LicenseProblems
          isLicenseValid={this.isLicenseValid}
          isLicenseExpired={this.isLicenseExpired}
          {...this.props}
        />
      );
    }

    if (this.hasConsentProblems()) {
      return <NoParentalConsent {...this.props} />;
    }
    /*
    if ( this.props.loginState === LoginState.loggedIn ) {
      this.checkLicenseAndConsent();
      if ( this.shouldntAllowLogin() ) {
        this.signOutUser();
      }
    }
    */

    const preferDesktopSite = getCookie('peekapak.preferDesktopSite') == 'true';
    if (
      !preferDesktopSite &&
      (this.getMobileOperatingSystem() === 'iOS' ||
        this.getMobileOperatingSystem() === 'Android')
    ) {
      return <AppDownloadRedirect />;
    }

    return (
      <>
        <SpinnerOverlay isShow={this.state.loginStarted} />
        <LoginBoxV2
          onSubmit={this.handleSubmit}
          errorMessage={this.state.loginError}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
  cognitoProfile: state.user.cognitoProfile,
  userProfile: state.user.userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUserProfile: (profile) => dispatch(setUserProfile(profile)),
});

const LoginDashboard = tracker.withPageView(
  connect(mapStateToProps, mapDispatchToProps)(BaseDashboard),
);
export default LoginDashboard;
