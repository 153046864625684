import { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import Spinner from 'react-spinkit';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import PeekavilleLogoBanner from './PeekavilleLogoBanner';
import ClassCodeCaptureForm from './ClassCodeCaptureForm';
import SignUpCaptureForm from './SignUpCaptureForm';
import { getSignUpInformation, signUpStudentAccount } from './BackendInterface';
import { SignUpStates } from './SignUpState';
import { getPossessiveForm, noWhiteSpace } from './GlobalFunctions';
import tracker from './tracker-interface';
import {
  fetchData,
  removeClassCode,
  submit,
  submitClassCode,
  tellError,
  tellSuccess,
} from './SignUpState';
import mainStreet from './images/bkg-peekaville-main-street.jpg';
import styles from './SCSS/ParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';
type Props = {
  signUpState: SignUpStateType;
  match: {
    params: {
      classCode?: string;
    };
  };
  onSubmitClassCode: () => any;
  onRemoveClassCode: () => any;
  onSubmitForm: () => any;
  onTellSuccess: () => any;
  onTellError: () => any;
  onFetchData: () => any;
};
type State = {
  savedClassCode: string;
  savedSignUpForm?: {
    parentsName: string;
    childsName: string;
    password: string;
  };
  errorObject?: {
    code: string;
    login: string;
    password: string;
  };
  errorText?: string;
  classCode: string;
  login: string;
  password: string;
  signUpInformation?: SignUpInformation;
};

const mapStateToProps = (state) => ({
  signUpState: state.signup.signUpState,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitClassCode: () => dispatch(submitClassCode()),
  onRemoveClassCode: () => dispatch(removeClassCode()),
  onSubmitForm: () => dispatch(submit()),
  onFetchData: () => dispatch(fetchData()),
  onTellSuccess: () => dispatch(tellSuccess()),
  onTellError: () => dispatch(tellError()),
});

class SignUp extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      errorObject: undefined,
      savedClassCode: '',
      savedRecord: undefined,
      classCode: props.match.params.classCode || '',
      login: '',
      password: '',
      signUpInformation: undefined,
    };
  }

  isSignUpInformationValid = () =>
    this.state.signUpInformation && !this.state.signUpInformation.status;
  onSubmitSignUp = async (signUpFormData) => {
    this.props.onSubmitForm();
    this.setState({
      savedSignUpForm: signUpFormData,
    });

    try {
      const payload = { ...signUpFormData, classCode: this.state.classCode };
      const creationResults = await signUpStudentAccount(payload);
      const { login, password } = creationResults;
      this.setState({
        login,
        password,
      });
      this.props.onTellSuccess();
      // tracker.sendClassCodeSignup(this.state.classCode);
    } catch (error) {
      this.setState({
        errorText: error.message,
        errorObject: error,
      });
      this.props.onTellError();
    }
  };
  onTryAgain = () => {
    this.props.onSubmitClassCode();
  };
  onTryClassCodeAgain = () => {
    this.setState({
      classCode: '',
    });
    this.props.onRemoveClassCode();
  };
  onSubmitClassCode = (stateFromForm: { classCode: string }) => {
    this.setState({
      classCode: stateFromForm.classCode,
      savedClassCode: stateFromForm.classCode,
    });
  };
  /* eslint-disable-next-line complexity */

  getDynamicContent = () => {
    const that = this;

    const insideComponent = () => {
      /* eslint-disable complexity */
      switch (that.props.signUpState) {
        case SignUpStates.withClassCode:
          return (
            <>
              <div>{deriveSentence()}</div>
              {this.isSignUpInformationValid() && (
                <SignUpCaptureForm
                  onSubmit={that.onSubmitSignUp}
                  savedForm={that.state.savedSignUpForm}
                />
              )}
            </>
          );

        case SignUpStates.withoutClassCode:
          return (
            <ClassCodeCaptureForm
              onSubmit={that.onSubmitClassCode}
              savedClassCode={that.state.savedClassCode}
            />
          );

        case SignUpStates.pending:
        default:
          return (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          );
      }

      function deriveSentence() {
        const { signUpInformation } = that.state;

        if (signUpInformation) {
          const fullName =
            getPossessiveForm(noWhiteSpace`${signUpInformation.salutation}${' '}
          ${signUpInformation.firstName}${' '}${signUpInformation.lastName}`);
          return (
            <>
              You are signing up for a myPeekaville account for your child who
              is in <strong>{fullName}</strong> class
              {signUpInformation.schoolName ? (
                <>
                  {' '}
                  at <strong>{signUpInformation.schoolName}</strong>.
                </>
              ) : (
                <>.</>
              )}
            </>
          );
        } // TODO: send error condition to error tracker

        return (
          <>
            Notice: Sorry but we couldn’t figure out your child’s classroom
            information. Please send a screenshot of this error message to
            support@peekapak.com so that we can help troubleshoot this problem.
            The class code that was provided is{' '}
            <strong>{that.state.classCode}</strong>.
            <div
              style={{
                textAlign: 'center',
                marginTop: '1rem',
              }}
            >
              <PrimaryButton small onClick={that.onTryClassCodeAgain}>
                Try Again
              </PrimaryButton>
            </div>
          </>
        );
      }
    };

    switch (this.props.signUpState) {
      case SignUpStates.withClassCode:
      case SignUpStates.withoutClassCode:
      case SignUpStates.pending:
        return (
          <div className={dialogStyles.dialog}>
            <div className={dialogStyles.dialogTitle}>
              Create an Account for Your Child
            </div>
            <div className={dialogStyles.dialogText}>
              <p>
                Welcome to myPeekaville! From here, you can create an account
                for your child and put them in the classroom that was created by
                their teacher.
              </p>
              {insideComponent()}
            </div>
          </div>
        );

      case SignUpStates.success:
        return (
          <div className={dialogStyles.dialog}>
            <div className={dialogStyles.dialogTitle}>
              Account Successfully Created!
            </div>
            <div className={dialogStyles.dialogText}>
              <p>
                If you provided us with your email address, please look for a
                confirmation email with your child's login name and password,
                for your records.
              </p>
              <p>Login name:</p>
              <div className={styles.login}>{this.state.login}</div>
              <p>Password:</p>
              <div className={styles.password}>{this.state.password}</div>
              <p>Login at:</p>
              <div className={styles.url}>
                <a
                  href={`${document.location.protocol}//${document.location.host}`}
                >
                  {document.location.host}
                </a>
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.errorMessage}>
            <p>{this.state.errorText}</p>
            <PrimaryButton orange small type='submit' onClick={this.onTryAgain}>
              Try Again
            </PrimaryButton>
          </div>
        );
    }
  };

  async componentDidMount() {
    if (this.state.classCode) {
      this.props.onFetchData();

      try {
        const signUpInformation = await getSignUpInformation(
          this.state.classCode,
        );
        this.setState({
          signUpInformation,
        });
        this.props.onSubmitClassCode();
      } catch (error) {
        this.props.onRemoveClassCode();
      }
    } else {
      this.props.onRemoveClassCode();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.classCode !== this.state.classCode) {
      if (this.state.classCode) {
        this.props.onFetchData();

        try {
          const signUpInformation = await getSignUpInformation(
            this.state.classCode,
          );
          this.setState({
            signUpInformation,
          });
          this.props.onSubmitClassCode();
        } catch (error) {
          this.props.onRemoveClassCode();
        }
      } else {
        this.props.onRemoveClassCode();
      }
    }
  }

  render() {
    return (
      <div className={styles.parentalConsent}>
        <div className={styles.logoContainer}>
          <PeekavilleLogoBanner />
        </div>
        <div className={styles.signUpContainer}>{this.getDynamicContent()}</div>
        <div className={styles.bottomBanner}>
          <img src={mainStreet} alt='img' className={styles.topImg} />
        </div>
      </div>
    );
  }
}

export default tracker.withPageView(
  connect(mapStateToProps, mapDispatchToProps)(SignUp),
);
