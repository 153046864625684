import { Component } from 'react';
import { Hub } from 'aws-amplify';
import { signOutUser } from './UserManagementHelpers';
import { getCookie, noWhiteSpace } from './GlobalFunctions';
import {
  openHostedUIForClever,
  openHostedUIForCleverM,
  openHostedUIForGoogle,
  openHostedUIForClassLink,
  reactivateUserSessionIfExists,
} from './UserManagementHelpers';
import NewSignUpStatusUpdate from './NewSignUpStatusUpdate';
import { getStore } from './ApplicationState';
import Analytics from './Analytics';
import { IdpType } from './peekapak-types/DataProtocolTypes';
import { LoginState } from './UserManagement';
type Props = {
  onShowErrorMessage: (arg0: string) => void;
  setProperty: (arg0: string, arg1: string) => void;
  loginState: string;
  location: {
    pathname: string;
    state: {
      nextPathname: string;
    };
    search: string;
  };
  history: {
    replace: (arg0: string) => void;
    push: (arg0: string) => void;
  };
};
type State = {
  isShowSpinner: boolean;
};

class OauthLogin extends Component<Props, State> {
  timeOutTimerId: TimeoutID;

  constructor(props: Props) {
    super(props);
    this.state = {
      isShowSpinner: true,
    };
    Hub.listen('auth', ({ payload: { event, data } }) => {
      this.clearTimeoutTimer(); // console.debug( `AWS Amplify Hub - Received event ${ event }` );

      switch (event) {
        case 'cognitoHostedUI':
          this.handleCognitoHostedUIEvent();
          break;

        case 'oAuthSignOut':
          signOutUser();
          break;

        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          handleFailure.bind(this)(data);
          break;

        default:
          console.warn(`AWS Amplify Hub - Did not handle event ${event}`);
          break;
      }
    }); // console.debug( `OauthLogin: installed Hub listener` );

    this.timeOutTimerId = setTimeout(() => {
      showProblemMessage.bind(this)();
    }, 45000);

    function handleFailure(data) {
      // console.debug('Handling amplify hub failure');
      const amplifyErrorMessage = data.message
        ? decodeURIComponent(data.message).replace(/\+/gi, ' ')
        : undefined;

      if (
        amplifyErrorMessage &&
        amplifyErrorMessage.includes('failed with error')
      ) {
        //
        // WARNING WARNING WARNING WARNING
        // this parsing is very brittle
        //
        const parts = amplifyErrorMessage.split(
          /(\w+ failed with error|[{}])/g,
        );

        if (isAccountLinked(parts[4])) {
          const errorObject = JSON.parse(`{ ${parts[4]} }`);
          console.warn(
            `AWS Amplify Hub received notice of account linking with external idP -- ${errorObject.username}`,
          );
          return;
        } else {
          console.error(
            `Unrecognized Google/Clever login error: ${amplifyErrorMessage}`,
          ); // logger.logException( new Error( `Google login ${ this.props.location.pathname } error: ${ amplifyErrorMessage }` ) );

          showProblemMessage.bind(this)();
          return;
        }
      } else {
        console.error(`Unhandled Google/Clever login error: ${data}`); // logger.logException( new Error( `Unhandled Google login ${ this.props.location.pathname } error: ${ data }` ) );

        showProblemMessage.bind(this)();
        return;
      }

      function isAccountLinked(checkError) {
        return checkError.includes('PreSignupExternalEmailAlreadyExists');
      }
    }

    function showProblemMessage() {
      this.props.onShowErrorMessage(
        `Sorry, we couldn't log you in. Please try again from our login page, and let us know if you keep on having this problem.`,
      );
      this.props.history.replace('/login');
    }
  }

  clearTimeoutTimer = () => {
    clearTimeout(this.timeOutTimerId);
  };
  handleCognitoHostedUIEvent = async () => {
    const that = this;

    try {
      await loginUserFromCognitoOAuth();
    } catch (error) {
      this.setState({
        isShowSpinner: false,
      });
      const message = processError(error);

      const userNotFoundMessages = [
        ErrorMessage.NO_ACCOUNT_FOUND_NOT_ROSTERED,
        ErrorMessage.NO_ACCOUNT_FOUND_INCOMPLETE,
        ErrorMessage.NO_ACCOUNT_FOUND_TEACHER_STUDENT_MIXUP,
      ];

      if (userNotFoundMessages.includes(message)) {
        this.props.history.replace('/noAccount'); // signout intentionally disabled because
        // signout from OAuth login will cause a redirect
        // to / causing whatever the user was doing to be
        // aborted
        // setTimeout( signOutUser, 15000 );
      } else {
        this.props.onShowErrorMessage(message);
      }
    }

    function processError(error) {
      if (error === `{'error':'invalid_grant'}`) {
        return 'There was a problem. Please try signing in again.';
      }

      const message = getUserFriendlyErrorMessage(error);

      if (!message) {
        // logger.logException( new Error( `Google login ${ that.props.location.pathname } error: ${ error }` ) );
        console.error(
          `Google/Clever login error = ${JSON.stringify(error, null, 2)}`,
        );
      }

      return message;
    }

    function getUserFriendlyErrorMessage(error) {
      if (!error.message) {
        return '';
      }

      const idP = getCookie('peekapak.idP');

      switch (error.message) {
        case 'User Pool account already exists':
          // logger.logException( new Error( `Google login ${ that.props.location.pathname } error: ${ error.message || error }` ) );
          return noWhiteSpace`A Peekapak account with this email \
            address already exists. Please use the email address and associated password \
            to login instead of the ${idP} Sign In feature. Please use your email \
            address and password to sign in`;

        case ErrorMessage.NO_ACCOUNT_FOUND_NOT_ROSTERED:
        case ErrorMessage.NO_ACCOUNT_FOUND_INCOMPLETE:
        case ErrorMessage.NO_ACCOUNT_FOUND_TEACHER_STUDENT_MIXUP:
        default:
          return error.message;
      }
    }

    async function loginUserFromCognitoOAuth() {
      try {
        await reactivateUserSessionIfExists();
        await waitForLoginCompletion();

        that.setState({
          isShowSpinner: false,
        });

        const userProfile = getStore().getState().user.userProfile;
        const idp = getCookie('peekapak.idP');

        const mapper: Record<string, IdpType> = {
          Clever: 'clever',
          CleverM: 'clever-m',
          ClassLink: 'classlink',
          Google: 'google',
        };
        await Analytics.login(userProfile, mapper[idp]);
        alreadyLoggedIn.bind(that)();
      } catch (error) {
        throw error;
      }

      function waitForLoginCompletion() {
        return new Promise<void>((resolve) => {
          const unsubscribe = getStore().subscribe(() => {
            const observedState = getStore().getState().user.loginState;

            if (
              observedState === LoginState.loggedIn ||
              observedState === LoginState.expiredSession ||
              observedState === LoginState.loggedOut
            ) {
              unsubscribe();
              return resolve();
            }
          });
        });
      }
    }

    function alreadyLoggedIn() {
      this.props.history.replace('/login');
    }
  };
  componentDidMount = () => {
    const that = this;

    function parseError(error) {
      if (
        error.includes('PreSignupExternalEmailAlreadyExists') ||
        error.includes('Exception processing authorization code')
      ) {
        return handleProviderLinkedAccount();
      } else {
        that.setState({
          isShowSpinner: false,
        });
        console.error(`Google/Clever login failed: ${error}`);
        return;
      }
    }

    function handleProviderLinkedAccount() {
      that.setState({
        isShowSpinner: false,
      });
      that.handleAccountLinkedOkay();
    }

    const query = new URLSearchParams(that.props.location.search);
    // console.debug(`Mounted and query is = `, query);
    const error_description = query.get('error_description');

    if (error_description) {
      parseError(error_description);
    }
  };
  handleGoogleSignIn = () => {
    openHostedUIForGoogle();
  };
  handleCleverSignIn = () => {
    openHostedUIForClever();
  };
  handleClassLinkSignIn = () => {
    openHostedUIForClassLink();
  };

  handleCleverMSignIn = () => {
    openHostedUIForCleverM();
  };
  handleAccountLinkedOkay = () => {
    const idP = getCookie('peekapak.idP');

    switch (idP) {
      case 'Clever':
        this.handleCleverSignIn();
        break;

      case 'CleverM':
        this.handleCleverMSignIn();
        break;

      case 'ClassLink':
        this.handleClassLinkSignIn();
        break;
      case 'Google':
      default:
        this.handleGoogleSignIn();
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.isShowSpinner && (
          <NewSignUpStatusUpdate
            pictureUrl='https://s3.amazonaws.com/peekaplatform/media30/images/apollo-with-whiteboard.png'
            contentTitle='Processing...'
            isShowSpinner={true}
          />
        )}
      </>
    );
  }
}

export default OauthLogin;
